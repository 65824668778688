import React from 'react';
import {StaticImage} from "gatsby-plugin-image";

function TicInfoBox(props) {
    return (
        <div>
            <div className="rn-address bg_color--3">
                <div className="inner d-flex justify-content-center align-items-center flex-column text-center">
                    <div className="px-4 py-1">
                        <StaticImage
                            src="../../../assets/images/rudnik/tic-litija-logo.png"
                            alt="Tourist Information Centre Litija – TIC Litija LOGO"
                            placeholder="blurred"
                            imgClassName="img-fluid"
                            loading="lazy"
                        />
                    </div>

                    <h4 className="title text-center d-inline-block mt--15">Tourist Information Centre Litija – TIC Litija</h4>
                    <p>Valvazorjev trg 10, 1270 Litija</p>

                    <p className="mt--15">
                        <a href="mailto:info@visitlitija.si">info@visitlitija.si</a>
                    </p>

                    <p><a href="tel:+38651312739">00386 51 312 739</a></p>
                    <p><a href="tel:+38670700484">00386 70 700 484</a></p>

                    <p className="mt--15">
                        <a href="https://www.facebook.com/rudniksitarjevec/" target="_blank">Mine Facebook Page</a>
                    </p>

                    <p>
                        <a href="https://www.facebook.com/visitlitija/" target="_blank">Facebook VisitLitija</a>
                    </p>

                </div>
            </div>
        </div>
    );
}

export default TicInfoBox;
