import React, { useState } from "react";
import { Link } from "gatsby";
import { FiCheck } from "react-icons/fi";
import MineGoogleMap from "../../../components/info/MineGoogleMap";
import TicInfoBox from "./TicInfo";
import ZkmsInfoBox from "./ZkmsInfoBox";
import ContactForm from "./ContactForm";
import MuseumBox from "./MuseumBox";

function CompanyInfo(props) {
    const [messageSent, setMessageSend] = useState(false);

    return (
        <div className="rn-contact-top-area ptb--120 bg_color--3">
            <div className="container">
                <div className="row ">
                    <div className="col-12 col-lg-8 col-xl-8 pr-lg-5">
                        <div className="">
                            <div className="section-title mb-5">
                                <h2 className="title">Reservation</h2>

                                {messageSent ? (
                                    <p>
                                        Thank you for you message. We will
                                        answer you as soon as possible.
                                    </p>
                                ) : (
                                    <ContactForm
                                        setFormSuccessStatus={(value) =>
                                            setMessageSend(value)
                                        }
                                    />
                                )}
                            </div>

                            <div className="section-title">
                                <h2 className="title">Who are we?</h2>
                                <p className="description">
                                    The Tourist Information Centre (TIC) offers
                                    information of the{" "}
                                    <a
                                        href="https://visitlitija.si"
                                        target="_blank"
                                    >
                                        ttourist offer in Litija
                                    </a>{" "}
                                    and its surroundings. TIC is the entry point
                                    for the Sitarjevec Litija Mine. The mine can
                                    be visited by prior notice from Thursday to
                                    Sunday, larger groups by arrangement. TIC
                                    organizes tourist trips for incoming guests.
                                    TIC Litija works with local products in a
                                    beautifully stocked shop which are also
                                    suitable as souvenirs or symbolic gifts. You
                                    can choose from unique wooden, ceramic,
                                    plaiting, textiles, various jewellery,
                                    natural soaps, teas and other unique handily
                                    decorated products.
                                </p>

                                <div className="description mb--40">
                                    <div className="mb-2">
                                        We expect you in TIC in the following
                                        working hours:
                                    </div>

                                    <ul className="list-style--1 pl-2">
                                        <li>
                                            <FiCheck />
                                            <strong>Monday</strong> closed
                                        </li>

                                        <li>
                                            <FiCheck />
                                            <strong>Tuesday</strong> from 10:00
                                            to 16:00
                                        </li>

                                        <li>
                                            <FiCheck />
                                            <strong>Wednesday</strong> from 9:00
                                            to 17:00
                                        </li>

                                        <li>
                                            <FiCheck />
                                            <strong>Thursday</strong> closed
                                        </li>

                                        <li>
                                            <FiCheck />
                                            <strong>Friday</strong> from 9:00 to
                                            15:00
                                        </li>
                                        <li>
                                            <FiCheck />
                                            <strong>Saturday</strong> from 9:00
                                            to 12:00
                                            <div className="saturday-visit-text saturday-special-offer">
                                                Present at the location of the mine reception.
                                            </div>

                                        </li>

                                        <li>
                                            <FiCheck />
                                            <strong>
                                                Sunday and public holidays
                                            </strong>{" "}
                                            closed
                                        </li>
                                    </ul>
                                </div>

                                <div className="description mb--40">
                                    <div className="mb-2">
                                        The schedule of views of the Sitarjevec
                                        Litija Mine is as follows:
                                    </div>

                                    <ul className="list-style--1 pl-2">
                                        <li>
                                            <FiCheck />
                                            <strong>Thursday</strong> from 9:00
                                            to 12:00 and 15:00 to 18:00
                                        </li>

                                        <li>
                                            <FiCheck />
                                            <strong>Friday</strong> from 9:00 to
                                            12:00 and 15:00 to 18:00
                                        </li>
                                        <li>
                                            <FiCheck />
                                            <strong>Saturday</strong> from 9:00
                                            to 12:00 and 15:00 to 18:00
                                            <div className="saturday-visit-text saturday-special-offer">
                                                It is possible to visit the Export Pit of the mine without prior notice every Saturday at 10:00 AM.
                                            </div>
                                        </li>
                                        <li>
                                            <FiCheck />
                                            <strong>Sunday</strong> from 9:00 do
                                            12:00 in 15:00 do 18:00
                                        </li>
                                    </ul>

                                    <small className="mt--20 d-inline-block">
                                        *A prior order is required to visit the
                                        mine. Groups can also be ordered outside
                                        the specified hours.
                                    </small>
                                    <small className="d-inline-block">
                                        *Price list of tours and other services
                                        can be found{" "}
                                        <Link to="/en/price-list">here</Link>.
                                    </small>
                                </div>
                            </div>
                        </div>

                        <div className="mt-4">
                            <div className="section-title">
                                <div className="description">
                                    <MineGoogleMap
                                        address={{
                                            lat: 46.05727097270354,
                                            lng: 14.831443234246294,
                                        }}
                                        googleApiKey="AIzaSyD_4eDYM8ZTWDEFoemtRivWJBYOmlHTNUU"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-lg-4 col-xl-4">
                        <TicInfoBox />
                        <ZkmsInfoBox />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CompanyInfo;
