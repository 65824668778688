import React, { useState } from 'react';

import { useForm } from "react-hook-form";
import axios from 'axios';

const SPLETNE_SI_API_KEY = 'OwNFYPpC46dXTGeYzVCLQqiFXcS0myu8';

function ContactForm({setFormSuccessStatus}) {

    const { register, handleSubmit, watch, formState: { errors } } = useForm();

    const onSubmit = data => {
        axios.post('https://services.spletne.si/rudnik-sitarjevec/kontakt',{
            data,
            key: SPLETNE_SI_API_KEY
        }).then((res) => {
            setFormSuccessStatus(true);
        }).catch(err => {
            console.log(err);
            setFormSuccessStatus(false);
        })
    };

    return (
        <div className="contact-form--1">
            <div className="row">
                <div className="col-12">
                    <div className="form-wrapper">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <label htmlFor="name">
                                <input
                                    {...register('name', {
                                        required: true,
                                    })}
                                    type="text"
                                    id="name"
                                    name="name"
                                    placeholder="Type in your name and surname*"
                                />
                                {errors.name && errors.name.type === "required" && (
                                    <span className="invalid-form-data">
                                        This input is required.
                                    </span>
                                )}
                            </label>

                            <label htmlFor="company">
                                <input
                                    {...register('company')}
                                    type="text"
                                    id="company"
                                    name="company"
                                    placeholder="Type in the name of the company, school, society, organisation (optional)"
                                />

                            </label>

                            <label htmlFor="email">
                                <input
                                    {...register('email', {
                                        required: true
                                    })}
                                    type="text"
                                    id="email"
                                    name="email"
                                    placeholder="Type in your e-mail address*"
                                />
                                {errors.email && errors.email.type === "required" && (
                                    <span className="invalid-form-data">
                                        This input is required.
                                    </span>
                                )}
                            </label>

                            <label htmlFor="phone">
                                <input
                                    {...register('phone', {
                                        required: true
                                    })}
                                    type="text"
                                    name="phone"
                                    id="phone"
                                    placeholder="Type in your phone number*"
                                />
                                {errors.phone && errors.phone.type === "required" && (
                                    <span className="invalid-form-data">
                                        This input is required.
                                    </span>
                                )}
                            </label>

                            <label htmlFor="message">
                                <textarea
                                    {...register('message', {
                                        required: true
                                    })}
                                    id="message"
                                    name="message"
                                    placeholder="Type in your message*"
                                />
                                {errors.message && errors.message.type === "required" && (
                                    <span className="invalid-form-data">
                                        This input is required.
                                    </span>
                                )}
                            </label>

                            <button className="btn-default size-md" type="submit" value="submit" name="submit" id="mc-embedded-subscribe">Send the message</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactForm;