import React from "react";
import { StaticImage } from "gatsby-plugin-image";

function ZkmsInfoBox(props) {
    return (
        <div className="rn-address bg_color--3 mb-5">
            <div className="inner d-flex justify-content-center align-items-center flex-column text-center">
                <div className="px-2 py-1">
                    <StaticImage
                        src="../../../assets/images/rudnik/razvojni-center-srca-slovenije-2.png"
                        alt="Logotip Razvojnega centra Srce Slovenije"
                        placeholder="blurred"
                        imgClassName="img-fluid"
                        loading="lazy"
                    />
                </div>

                <h4 className="title d-inline-block mt--15">
                    Razvojni center Srca Slovenije
                </h4>
                <p>Kidričeva cesta 1, 1270 Litija</p>
                <p>ID za DDV: SI35375701</p>
                <p>MŠ: 1489208000 </p>
                <p>SI56 6000 0000 1057 371</p>

                <p className="mt--15">
                    <a href="https://srce-slovenije.si/" target="_blank">
                        Website
                    </a>
                </p>
            </div>
        </div>
    );
}

export default ZkmsInfoBox;
