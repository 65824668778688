import React from 'react';
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ children, location, description, title, image, schemaMarkup }) {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        author
                    }
                }
            }
        `
    );

    const metaDescription = description || site.siteMetadata.description;
    const defaultTitle = title || site.siteMetadata?.title;

    return (
        <Helmet titleTemplate={`%s - ${defaultTitle} `}>
            <html lang="en" />
            <title>{title}</title>

            <meta http-equiv="cache-control" content="no-cache" />
            <meta http-equiv="expires" content="0" />
            <meta http-equiv="pragma" content="no-cache" />

            <link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-touch-icon.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16.png" />
            <meta name="msapplication-TileColor" content="#da532c" />
            <meta name="theme-color" content="#ffffff"/>

            <meta name="viewport" content="width=device-width,initial-scale=1.0"/>
            <meta charSet="utf-8"/>
            <meta name="description" content={site.siteMetadata.description}/>

            {/* Open graph */}
            {location && <meta property="og:url" content={location.href}/>}
            <meta property="og:image" content={image || '/logo.svg'}/>
            <meta property="og:title" content={title} key="ogtitle"/>
            <meta property="og:site_name" content={site.siteMetadata.title}/>
            <meta property="og:description" content={description} key="ogdesc"/>

            {schemaMarkup &&
            <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
            }
        </Helmet>
    )
}

SEO.defaultProps = {
    lang: `sl`,
    meta: [],
    description: ``,
};

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
};

export default SEO
