import React from 'react';
import SEO from "../../components/en/seo/Seo";
import Header from "../../components/en/headers/Header";
import Footer from "../../components/en/layouts/Footer";
import CompanyInfo from "../../components/en/info/CompanyInfo";
import ContactHeader from "../../components/en/info/ContactHeader";

function ContactAndInformation(props) {
    return (
        <>
            <SEO title="Contact and information"
                 description="The Tourist Information Centre (TIC) offers information of the tourist offer in Litija and its surroundings. TIC is the entry point for the Sitarjevec Litija Mine. The mine can be visited by prior notice from Thursday to Sunday, larger groups by arrangement. TIC organizes tourist trips for incoming guests. "
            />
            <Header />
            <ContactHeader/>
            <CompanyInfo />
            <Footer/>
        </>
    );
}

export default ContactAndInformation;